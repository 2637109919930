import React from 'react';
import logo from './img/Edilians.png';
import tuileEdilians from './img/icone-tuile-sos-toiture.webp';
import pluieEdilians from './img/grele.png';
import coeurEdilians from './img/aide-sos-toiture.webp';


function Header() {
    return (
        
        <div>
        <header></header>
       <div className='headerWrap'>
        <section id="background-header"className=''>
            <div className='containerLogo'>
                <div className='rowLogo'>
                    <div className='fakeContainer'>
                        <div className='divFake'></div>

                    </div>
                    <div className='containerEdilians'>
                        <div className='divFake'>
                            <div className='widgetWrap'>
                                <div className='divCenter'>
                                    <div className='logoContainer'>
                                        <img id="logo" src={logo}></img>

                                    </div>
   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section id="sos-toiture" className="headerSection">
          <div className="container">
              <div className="containerFlex">
                  <div id="textServiceSos">
                    <div id='containerSOS'>
                        <div id='wrapSOS'>
                            <div id='wrapTitle'>
                                <h2 className="titleHeader">Service SOS toiture</h2>

                                <div className='mainText'>
                                    <div className='container'>
                                        <div className='texts'>
                                        <p>Vous avez subi un sinistre sur votre toiture suite à une intempérie et il y a donc urgence ! Nous avons mis en place « SOS TOITURE » : un service inédit, totalement gratuit pour simplifier la gestion logistique de la rénovation de votre toiture.</p>
<p>Edilians <strong>vous garantit la priorité pour la livraison de vos tuiles et accessoires terre cuite</strong> associés à votre toit.</p>
<p>Pour bénéficier de ce service, il suffit de remplir le formulaire ci-dessous et d’apporter l’ensemble des pièces justificatives demandées.
Vous serez ensuite rappelé par l’un de nos conseillers pour valider votre dossier et votre couvreur supervisera l’ensemble de la rénovation de votre toit.</p>

                                        </div>

                                        
                                    </div>


                                </div>


                            </div>

                        <div className='btnContainer'>
                            <div className='widgetContainer'>
                                <div className='widgetWrapper'>

                                    <a href='#form-sos'>
                                        <span>
                                            <span>
                                            Accédez au formulaire
                                            </span>
                                            
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>

                        </div>



                    </div>

                  </div>
                  <div id="rightFakeContainer">
                    <div className='columnWrap'>
                        <div className='widgetWrap'>
                            
                        </div>
                    </div>
                  </div>
              </div>


          </div>

        </section>

        <section id="divider" className="headerSection">
            <div className='divider'>

            </div>
        </section>
        <section id="serviceDansUrgence" className="headerSection">
            <div className="container">
                <div className="row">
                    <div className="containerColumns">
                        <div className="containerWrap">
                            <div className="containerPosition">
                                <div className="containerTitle">
                                    <div className="pads">
                                        <h2><b>A votre service dans l'urgence</b></h2>
                                    </div>
                                </div>

                            </div>

                            <section id="containerInfos">
                                <div className="columnGap">
                                    <div className="rowGap">
                                        <div className="colGap">
                                            <div className="contentCol">
                                                <div className="colWrap">
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="elemImg">
                                                                <img src={tuileEdilians}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer paddingL">
                                                            <h3>Un coeur de métier à votre de service</h3>

                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="textEditor">
                                                                <p><span>
                                                                    Fort de son patrimoine, son expérience et son ancrage territorial, Edilians développe et fabrique des solutions innovantes pour votre toit.
                                                                </span></p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colGap">
                                            <div className="contentCol">
                                                <div className="colWrap">
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="elemImg">
                                                                <img src={pluieEdilians}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer paddingL">
                                                            <h3>Une demande urgente prioritaire</h3>

                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="textEditor">
                                                                <p><span>
                                                                    Edilians met en place un parcours logistique optimisé qui vous assurera une priorité dans la livraison de son stock de tuiles terre cuite pour la réparation de votre toit.
                                                                </span></p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colGap">
                                            <div className="contentCol">
                                                <div className="colWrap">
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="elemImg">
                                                                <img src={coeurEdilians}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer paddingL">
                                                            <h3>Edilians s'engage à vos côtés</h3>

                                                        </div>
                                                    </div>
                                                    <div className="flexRoxElem">
                                                        <div className="widgetContainer">
                                                            <div className="textEditor">
                                                                <p><span>
                                                                    Ensemble, façonnons un avenir durable. Par nos engagements sociétaux, nous vous garantissons des produits responsables qui répondent aux enjeux environnementaux de demain.
                                                                </span></p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>

                </div>

            </div>
        </section>


       </div>
       </div>

    );
  }
  
  export default Header;
  
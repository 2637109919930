import favicon from './img/favicon_2.ico?v=2';
import favicon2 from './img/favicon.png';


function App() {
  return (
<>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon2}/>        
        <link rel="shortcut icon" href={favicon} type="image/x-icon"/>
        </>
  
      

  );
}

export default App;

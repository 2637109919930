import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {toast} from 'react-toastify';


// ERREUR MESSAGE SERVEUR
const emailError = "Veuillez renseignez un e-mail valide"
const fileError = "doit etre au format  .jpg, .png, or .pdf et ne doit pas éxceder 15Mo"

const recaptchaKey = "6Ldgg4EmAAAAAJA7hyLp3lBeU_f_DrEDnYzXtGXd"



const Form = () => {
const [formData, setFormData] = useState({
// Initialize form data state with default values
nom: '',
prenom: '',
email: '',
telephone: '',
adresse: '',
commune: '',
adresseSinistre: '',
dateSinistre: '',
typeSinistre: '',
attestationPriseEnCharge: null,
surfaceToiture: '',
fabricantTuiles: '',
codePostal: '',
isolation:false,
responsabilite:false,
photovoltaique:false,
noAdresseSinistre:'',
noCodepostalSinistre:'',
noCommuneSinistre:'',
nointerest:false,
handleRecaptchaChange:null,
photoTuile:null,
devisFile:null
});

// set all Errors from the React form

const [inputErrors, setInputErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(0);
const [showPopup, setShowPopup] = useState(false);


const reloadLocation = ()=>{
  setShowPopup(false)
  window.location.reload();

}

const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);

// File Uploader
const [progress, setProgress] = useState(0);

const handleRecaptchaChange = (value) => {
  // Store the reCAPTCHA response value in the component's state
  setIsRecaptchaValid(!!value);

  setFormData((prevFormData) => ({
    ...prevFormData,
    handleRecaptchaChange: value, // Store the file object in form data state
    }));
};



const handleFileUpload = (files, e) => {
  const reader = new FileReader();

  reader.onloadstart = () => {
    setProgress(0);
  };

  reader.onprogress = (e) => {
    const { loaded, total } = e;
    const percentCompleted = Math.floor((loaded / total) * 100);
    setProgress(percentCompleted);
  };

  reader.onload = () => {
    // File has been successfully loaded
    setProgress(100);
    // Perform further processing or handle the loaded file here
  };

  reader.readAsDataURL(files[0]);
};





const handleInputChange = (e) => {
const { name, value, type, files } = e.target;

setInputErrors({})
if (type === 'file') {

  handleFileUpload(files, e)

  setFormData((prevFormData) => ({
  ...prevFormData,
  [name]: files[0], // Store the file object in form data state
  }));

} else if (type === 'checkbox') {

  setFormData((prevFormData) => ({
  ...prevFormData,
  [name]: e.target.checked, // Store the checked value for checkboxes
  }));

  if(name === 'nointerest'){

    formData.isolation = !e.target.checked
    formData.photovoltaique = !e.target.checked
  } else if (name === 'isolation' || name === 'photovoltaique'){
    formData.nointerest = false
  }


} else if (type === 'radio'){
  if(name === 'adresseSinistre' && value === "Oui"){
    formData.responsabilite =  true
    formData.adresseSinistre = "Oui"
    
  } 
  else if(name === 'adresseSinistre' && value === "Non"){
    formData.responsabilite =  false
    formData.adresseSinistre = "Non"
    
  } 
  else{
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: DOMPurify.sanitize(value), // Store the value object in form data state
      }));
  }


}


else {
  


setFormData((prevData) => ({
...prevData,
[name]: DOMPurify.sanitize(value),

}));
}
};

const validateForm = () => {

// Check for errors
const errors = {};


const {
nom,
prenom,
email,
telephone,
adresse,
commune,
commentaires,
adresseSinistre,
dateSinistre,
typeSinistre,
codePostal,
attestationPriseEnCharge,
surfaceToiture,
fabricantTuiles,
responsabilite,
nomEntreprise,
emailEntreprise,
telephoneEntreprise,
devisFile,
photovoltaique,
isolation,
nointerest,
certify,
} = formData;


// if(handleRecaptchaChange == ''){
//   errors.robot = "Vous devez affirmer que vous n'êtes pas un robot !";

// }

if (!nom || nom.length > 50) {
  errors.nom = "Le champ ne doit pas être vide (Maximum 50 caractères autorisé pour Nom)";
}
if (!prenom ||prenom.length > 50) {
  errors.prenom ="Le champ ne doit pas être vide (Maximum 50 caractères autorisé pour Prénom)";
}
if (!commune || commune.length > 50) {
  errors.commune = "Le champ ne doit pas être vide (Maximum 50 caractères autorisé pour Commune)";
}

if (!validateEmail(email)) {
  errors.email = (emailError);
}

if (!validatePhoneNumber(telephone)) {
  errors.telephone = "Merci d'entre un numéro de téléphone valide";
}

if (!adresse || adresse.length > 255) {
  errors.adresse = "Le champ ne peut pas être vide, L'adressse ne peut contenir que 255 caractères";
}
if (!codePostal || codePostal.length > 10) {
  errors.codePostal = "Le champ ne peut pas être vide, Le code postal  ne peut contenir que 10 caractères";
}

if (typeSinistre !== 'Total' && typeSinistre !== 'Partiel') {
  errors.typeSinistre = "Merci de choisi un type de sinistre (Total ou Partiel ?)";

}
if (!commentaires || commentaires.length > 255) {
  errors.commentaires = "Merci de renseigner une description sur votre besoin";

}

if (!validateDate(dateSinistre)) {
  errors.dateSinistre = "Merci de choisir une date valide (format DD/MM/YYYY)";
}

if (!validateFileSize(attestationPriseEnCharge, 20) && attestationPriseEnCharge !=="") {
  errors.attestationPriseEnCharge = "Attestation de prise en charge " + fileError;
}

const validSurfaceChoices = [
'Je ne sais pas',
'de 0 à 100m2',
'de 101 à 200m2',
'de 201 à 300m2',
'de 301 à 400m2',
];

if (!validSurfaceChoices.includes(surfaceToiture)) {
  errors.validSurfaceChoices = "Veuillez choisir une surface de toiture valide";
}

const validFabricantChoices = [
'Je ne sais pas',
'Edilians (anciennement Imerys)',
'Terreal',
'Monier',
'Koramic',
'Tejas Borja',
'Escandella',
'Autres',
];

if (!validFabricantChoices.includes(fabricantTuiles)) {
  errors.validFabricantChoices = "Veuillez choisir une fabricant de tuiles valide"
}

// Validate PhotoDeVotreTuile input
const photoTuile = formData.photoTuile;

if(photoTuile !== null){
  if (!validateFileSize(photoTuile, 20)) {
    errors.photoTuile = "Photo de Tuile " +  fileError;
  }

}

if(devisFile !== null){
if (!validateFileSize(devisFile, 20)) {
  errors.devisCouvreur = "Le devis du couvreur " +  fileError;
}
}



// Validate Responsabilité input
if (!responsabilite) {
  errors.responsabilite = "Merci de confirmer que vous avez bien l'autorité pour engager les travaux sur ce toit"

}

// Validate Nom de l'entreprise input
if (!nomEntreprise || nomEntreprise.length > 60) {
  errors.nomEntreprise = "Veuillez renseigner un nom d'entreprise (max. 60 caractères)";

}

// Validate Email de l'entreprise input
if (!validateEmail(emailEntreprise)) {
//('setFormError('Please provide a valid Email de l\'entreprise (max 60 characters).');
  errors.emailEntreprise = emailError;
}

// Validate Téléphone de l'entreprise input
if (!validatePhoneNumber(telephoneEntreprise)) {
// setFormError('Please provide a valid Téléphone de l\'entreprise (max 60 characters).');
  errors.telephoneEntreprise = "Veuillez entre un numéro de téléphone valide";

}


// Validate mandatory inputs in Les informations liées à votre projet
if (!certify) {
  // setFormError('Please certify that you have read and accept the Data Protection Policy.');
  errors.certify = "Veuillez aire et Accepter notre règle de protection des Données et de confidentialité"

}


if(!adresseSinistre){ 
  errors.adresseSinistre = "Merci de renseigner tous les champs obligatoires !";
}

const isFalse = [photovoltaique,isolation,nointerest]
if(!isFalse.includes(true)){
  errors.infosMarket = "Merci de selectionner au moins une option"
}


// Update inputErrors state

setInputErrors(errors);


// Display error messages
if (Object.keys(errors).length > 0) {

  toast.error(`Il y a ${Object.keys(errors).length} erreurs dans le formulaire, veuillez les corriger`, {
    position: "top-center",
    autoClose: 1400,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  
  return false;

} else {
    
  return true;
}

};

const validateEmail = (email) => {
// Regular expression for email validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
return emailRegex.test(email);
};

const validatePhoneNumber = (phoneNumber) => {
// Regular expression for phone number validation
const phoneRegex = /^\d{10}$/; // Assuming 10-digit phone number format
return phoneRegex.test(phoneNumber);
};

const validateDate = (date) => {
// Regular expression for date validation (YYYY-MM-DD format)
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
return dateRegex.test(date);
};

const validateFileSize = (file, maxSize) => {
if (!file) {
return false;
}
const fileSizeInMB = file.size / (1024 * 1024);
return (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') && fileSizeInMB <= maxSize; }; 
  
const handleSubmit=  async (e)=> {
  e.preventDefault();


  if (isSubmit !== 0 || !isRecaptchaValid) {
    return; // Prevent form submission if already submitting or reCAPTCHA is invalid
  }

  if (validateForm()) {

    setIsSubmit(1);

    try {

      
      //  var id = toast.loading("Soumission de votre demande en cours...")
  
        // toast.update(id, { render: "Soumission de votre demande en cours...", type: "pending", isLoading: false , position: "top-center",
        // autoClose: 10000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,
        // theme: "light",});
        // // Handle the response as needed
  
      const formDataToSend = new FormData();
        for (const [key, value] of Object.entries(formData)) {
        formDataToSend.append(key, value);
        }
        var response = await axios.post('https://xdjj-vqgo-2lfl.f2.xano.io/api:ReZsS_vk/forms', formDataToSend);
  
        if(response.status === 200){
  
          
          setIsSubmit(3)
           // création du pop après 2000ms

            setTimeout(() => {
              setShowPopup(true)
            }, 1000);


          // enlever le reCaptacha
  

  
        } else {
  
          toast.error("Erreur lors de la soumission, veuillez ressayer",{isLoading: false , position: "top-center",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",});
  
          setIsSubmit(2)
  
  
        }
  


    } catch (error) {
      // Handle fetch error

          if (error.response && error.response.status === 401) {
            toast.error('Erreur lors de la soumission, vous n\'êtes pas autorisé');
          } else {
            toast.error('Erreur lors de la soumission, veuillez ressayer');
          }
     
          const erreurServeur = error.response.data.message
          
          if(erreurServeur === "Missing param: email"){

            toast.error(emailError, { isLoading: false , position: "top-center",
            autoClose: 1400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",});

          }
          else if (erreurServeur === "Missing file ressource."){
            toast.update(fileError, {isLoading: false , position: "top-center",
            autoClose: 1400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",});
    
          }
          setIsSubmit(2)

  
      } finally {}
 
    


  } 

}


  return (
    
  <div id='form-sos' onSubmit={handleSubmit}>
    <ToastContainer />
    <div className='form-container'>
      <div className='form-row'>
        <div className='col100'>
          <div className='columnWrap'>
            <div className='widgetWrap'>

              <div id="form-titleContainer">
                <div className="widgetContainer">
                  <h2>Votre formulaire<br />
                    <b>Service SOS Toiture</b></h2>
                </div>

              </div>

              <div id='form-formContainer'>
                <div className='widgetContainer'>

                  <form method='post' name='Formulaire SOS Toiture'>

                    <div className='form-wrapper'>
                      <div className='formSection'>
                        <b>Vos coordonnées</b>
                      </div>

                      <div className='form-input'>
                        <label className='obligatoire'>Nom</label>
                        <input type="text" placeholder='Insérez votre nom' name="nom" value={formData.nom}
                          onChange={handleInputChange} />
                        {inputErrors.nom && <div className='redError'>{inputErrors.nom}</div>}
                      </div>

                      <div className='form-input'>
                        <label className='obligatoire'>Prénom</label>
                        <input placeholder='Insérez votre prénom' type="text" name="prenom" value={formData.prenom} onChange={handleInputChange} />
                        {inputErrors.prenom && <div className='redError'>{inputErrors.prenom}</div>}
                      </div>

                      <div className='form-input'>
                        <label className='obligatoire'>Email</label>
                        <input placeholder='Insérez votre email' type="email" name="email" value={formData.email} onChange={handleInputChange} />
                        {inputErrors.email && <div className='redError'>{inputErrors.email}</div>}
                      </div>

                      <div className='form-input'>
                        <label className='obligatoire'>Téléphone</label>
                        <input placeholder='Insérez votre téléphone' type="text" name="telephone" value={formData.telephone} onChange={handleInputChange} />
                        {inputErrors.telephone && <div className='redError'>{inputErrors.telephone}</div>}
                      </div>

                      <div className='form-input'>
                        <label className='obligatoire'>Adresse Postale</label>
                        <input placeholder='Insérez votre adresse'  type='text' name="adresse" value={formData.adresse} onChange={handleInputChange} />
                        {inputErrors.adresse && <div className='redError'>{inputErrors.adresse}</div>}
                      </div>

                      <div id='codePostal' className='form-input'>
                        <label className='obligatoire'>Code Postal</label>
                        <input placeholder='Insérez votre code postal'   type='text' name="codePostal" value={formData.codePostal} onChange={handleInputChange} />
                        {inputErrors.codePostal && <div className='redError'>{inputErrors.codePostal}</div>}
                      </div>

                      <div id='commune' className='form-input'>
                        <label className='obligatoire'>Commune</label>
                        <input placeholder='Insérez votre commune'   type="text" name="commune" value={formData.commune} onChange={handleInputChange} />
                        {inputErrors.commune && <div className='redError'>{inputErrors.commune}</div>}
                      </div>
                    </div>

                    <div className="dividerForm">
                        <hr/>				
                      </div>

                    <div className='formSection'>
                      <b>Les informations liées à votre sinistre</b>
                    </div>

                    <div className='flexCol form-input row100 containerBtn'>

                      <label className='obligatoire'>
                      L'adresse de mon bien sinistré est la même que l'adresse inscrite ci-dessus (Section "Vos coordonnées")
                      </label>
                      <div className="radioBtn">
                            <span className='radioContainer'>
                            <input id="adresseSinistreOui"  type="radio" name="adresseSinistre" value="Oui" onChange={handleInputChange} />
                            <label  for="adresseSinistreOui" >   Oui</label>
                            </span>
                          
                            <span className='radioContainer'>
                            <input  id="adresseSinistreNon"  type="radio" name="adresseSinistre" value="Non" onChange={handleInputChange} />
                            <label  for="adresseSinistreNon">Non</label>
                            </span>
                      </div>
                        {inputErrors.adresseSinistre && <div className='redError'>{inputErrors.adresseSinistre}</div>}
                    </div>


                    {formData.adresseSinistre === 'Non' && (

                      <div className='formWrapperNoSinistre'>
                      <div className='form-input'>
                        <label className='obligatoire'>Adresse Postale</label>
                        <input placeholder='Insérez votre adresse'  type='text' name="noAdresseSinistre" value={formData.adresseSinistre === 'Oui' ? formData.adresse : formData.noAdresseSinistre} onChange={handleInputChange} />
                      </div>

                      <div id='codePostal' className='form-input'>
                        <label className='obligatoire'>Code Postal</label>
                        <input placeholder='Insérez votre code postal'   type='text' name="noCodepostalSinistre" value={formData.adresseSinistre === 'Oui' ? formData.codePostal : formData.noCodepostalSinistre}  onChange={handleInputChange} />
                      </div>

                      <div id='commune' className='form-input'>
                        <label className='obligatoire'>Commune</label>
                        <input placeholder='Insérez votre commune'   type="text" name="noCommuneSinistre" value={formData.adresseSinistre === 'Oui' ? formData.commune : formData.noCommuneSinistre}  onChange={handleInputChange} />
                      </div>

                      </div>


                    )}



                    


                      <div className='sinistreContainer'>

                        <div className='sinistreContainer-left'>

                        <div className='form-input' id='dateSinistre'>
                          <label className='obligatoire'>
                            Date du sinistre: </label>
                          <input id='dateSinistre' placeholder="Choisissez une date" type="date" name="dateSinistre" value={formData.dateSinistre}
                            onChange={handleInputChange} />

                          {inputErrors.dateSinistre && <div className='redError'>{inputErrors.dateSinistre}</div>}
                        </div>

                        <div className='flexCol form-input containerBtn'>

                        <label className='obligatoire'>
                        Type sinistre                      
                        </label>
                        <div className="radioBtn">
                              <span className='radioContainer'>
                              <input id="typeSinistreTotal" type="radio" name="typeSinistre" value="Total" onChange={handleInputChange} />{' '}
                              <label for="typeSinistreTotal">   Total</label>
                              </span>
                            
                              <span className='radioContainer'>
                              <input id="typeSinistrePartiel" type="radio" name="typeSinistre" value="Partiel" onChange={handleInputChange} />{' '}
                              <label for="typeSinistrePartiel">Partiel</label>
                              </span>

                        </div>
                        {inputErrors.typeSinistre && <div className='redError'>{inputErrors.typeSinistre}</div>}
                        </div>

                        <div className='form-input selectContainer' >
                          <label  className='obligatoire'>
                            Nom du fabricant de vos tuiles : </label>
                          <select name="fabricantTuiles" value={formData.fabricantTuiles} onChange={handleInputChange}>
                            <option value="" placeholder='choisissez une option...'></option>
                    
                            <option value="Edilians (anciennement Imerys)">
                              Edilians (anciennement Imerys)
                            </option>
                            <option value="Terreal">Terreal</option>
                            <option value="Monier">Monier</option>
                            <option value="Koramic">Koramic</option>
                            <option value="Escandella">Escandella</option>
                            <option value="Tejas Borja">Tejas Borja</option>
                            <option value="Autres">Autres</option>
                            <option value="Je ne sais pas">Je ne sais pas</option>
                          </select>
                          {inputErrors.validFabricantChoices && <div className='redError'>{inputErrors.validFabricantChoices}</div>}
                        </div>

                        </div>
                        
                        
                        <div className='sinistreContainer-right'>


                        <div className='form-input fileContainer'>
                        <label  className='obligatoire'>Attestation de prise en charge par votre assurance <span className='tailleMax'> <br></br>(Taille maximale : 15 mo - Fichiers autorisés : .jpg, .pdf, .png)</span></label>
                        <div className="file-div">
                          <button className="btn-upload">{formData.attestationPriseEnCharge == null ? ('choisir le fichier...') : ('fichier téléchargé !')}</button>
                          <input className='file' type="file" name="attestationPriseEnCharge" accept=".jpg,.png,.pdf"
                            onChange={handleInputChange} />
{/* 
                          <progress value={progress} max="100" />
                         */}
                          {inputErrors.attestationPriseEnCharge && <div className='redError'>{inputErrors.attestationPriseEnCharge}</div>}
                        </div>
                      </div>

                      <div className='form-input selectContainer'>
                        <label  className='obligatoire'>
                          Surface totale toiture en m²: </label>
                        <select name="surfaceToiture" value={formData.surfaceToiture} onChange={handleInputChange}>
                          <option value="" placeholder='choisissez une option...'></option>
                     
                          <option value="de 0 à 100m2">de 0 à 100m2</option>
                          <option value="de 101 à 200m2">de 101 à 200m2</option>
                          <option value="de 201 à 300m2">de 201 à 300m2</option>
                          <option value="de 301 à 400m2">de 301 à 400m2</option>
                          <option value="Je ne sais pas">Je ne sais pas</option>
                        </select>
                        {inputErrors.validSurfaceChoices && <div className='redError'>{inputErrors.validSurfaceChoices}</div>}
                      </div>




                      <div className='form-input fileContainer'>
                        <label>Photo de votre tuile (Recto et verso ) <span className='tailleMax'> <br></br>(Taille maximale : 15 mo - Fichiers autorisés : .jpg, .pdf, .png)</span></label>
                        <div className="file-div">
                          <button className="btn-upload">{formData.photoTuile == null ? ('choisir le fichier...') : ('fichier téléchargé !')}</button>
                          <input className='file' type="file" name="photoTuile" accept=".jpg,.png,.pdf" onChange={handleInputChange}
                           />
                          {inputErrors.photoTuile && <div className='redError'>{inputErrors.photoTuile}</div>}
                        </div>
                      </div>
                        </div>
                          

                      </div>




                  


                      <div className='form-input col100'>
                      <label className='obligatoire'>
                        Commentaires sur votre sinistre ( modèle de tuile, coloris) : </label>
                        <textarea name="commentaires" placeholder="Modèle de la tuile, coloris, etc..."
                          onChange={handleInputChange} />
                           {inputErrors.commentaires && <div className='redError'>{inputErrors.commentaires}</div>}
                        </div>

                        <div className='form-input checkboxContainer'>
                          <label className='obligatoire'>Responsabilité : </label>
                          <div className='field-group'>
                            <span className='field-option'>
                              <input className='field-checkbox' id='responsabiliteField'
                                type="checkbox"
                                name="responsabilite"
                                value={formData.responsabilite}
                                checked={formData.responsabilite}
                                onChange={handleInputChange}
                                
                              />
                              
                              <label for="responsabiliteField" className='field-notes'  id='autorite'>Je confirme que j'ai autorité pour engager les travaux sur cette toiture</label>      
                            </span>
                            {inputErrors.responsabilite && <div className='redError'>{inputErrors.responsabilite}</div>}
                          </div>
                       </div>
                       


                      <div className="dividerForm">
                        <hr/>				
                      </div>
                      
                      <div className='formSection'>
                        <b>Les informations liées au couvreur que vous avez envisagé</b>
                      </div>


       
                     <div className='form-input row100'>
                        <label  className='obligatoire'>
                          Nom de l'entreprise:  </label>
                          <input
                            type="text"
                            name="nomEntreprise"
                            placeholder="Nom de l'entreprise que vous avez envisagé"
                            onChange={handleInputChange}
                            maxLength="60"
                            
                          />
                           {inputErrors.nomEntreprise && <div className='redError'>{inputErrors.nomEntreprise}</div>}
                      </div>


                      <div className='companyContainer'>
                                              
                      <div className='form-input'>
                        <label  className='obligatoire'>
                          Email de l'entreprise:  </label>
                          <input
                            type="text"
                            name="emailEntreprise"
                            placeholder="Email du couvreur envisagé"
                            onChange={handleInputChange}
                            maxLength="60"
                            
                          />
                          {inputErrors.emailEntreprise && <div className='redError'>{inputErrors.emailEntreprise}</div>}
                        </div>
          
                      <div className='form-input'>
                          <label  className='obligatoire'>Téléphone de l'entreprise:</label>
                          
                          <input
                              type="text"
                              name="telephoneEntreprise"
                              placeholder="Téléphone du couvreur envisagé"
                              onChange={handleInputChange}
                              maxLength="60"
                              
                          />
                            {inputErrors.telephoneEntreprise && <div className='redError'>{inputErrors.telephoneEntreprise}</div>}
                          
                      </div>




                      </div>


                      <div className='form-input fileContainer'>
                        <label>
                          Si vous avez déjà le devis d'un couvreur, téléchargez-le ici<span className='tailleMax'> (Taille maximale : 15 mo - Fichiers autorisés : .jpg, .pdf, .png)</span>
                        </label>

                        <div className="file-div">
                        <button className="btn-upload">{formData.devisFile == null ? ('choisir le fichier...') : ('fichier téléchargé !')}</button>
                        <input className='file'
                          type="file"
                          name="devisFile"
                          accept=".jpg,.png,.pdf"
                          onChange={handleInputChange}
                        />
                        {/* <progress value={progress} max="100" /> */}
                        
                        {inputErrors.devisCouvreur && <div className='redError'>{inputErrors.devisCouvreur}</div>}
                        </div>

                    
                      </div>


                      <div className="dividerForm">
                        <hr/>				
                      </div>
                      
                      <div className='formSection'>
                        <b>Les informations liées à votre projet</b>
                      </div>
                
                     

                                             
                      <div className='form-input checkboxContainer2'>
                          <div className='field-group'>
                            <span className='field-option'>
                                <input className='field-checkbox interest'
                                type="checkbox"
                                id='photovoltaique'
                                name="photovoltaique"
                                value={formData.photovoltaique}
                                onChange={handleInputChange}
                                checked={formData.photovoltaique}
                              />
                              <label id='autorite' for='photovoltaique' className='field-notes' >Je suis intéressé par la mise en place d'une installation photovoltaïque sur ma toiture afin de produire mon électricité</label>      
                            </span>
                          </div>
                       </div>
                      <div className='form-input checkboxContainer2'>
                          <div className='field-group'>
                            <span className='field-option'>
                                <input id='isolation' className='field-checkbox interest'
                                type="checkbox"
                                name="isolation"
                                value={formData.isolation}
                                checked={formData.isolation}
                                onChange={handleInputChange}
                              />
                              <label  id='autorite' for='isolation' className='field-notes'>Je suis intéressé par l'amélioration de l'isolation de ma toiture dans
                          le cadre d'une rénovation énergétique : </label>      
                            </span>
                          </div>
                       </div>
                      <div className='form-input checkboxContainer2'>
                          <div className='field-group'>
                            <span className='field-option'>
                                <input className='field-checkbox'
                                id='nointerest'
                                type="checkbox"
                                name="nointerest"
                                value={formData.nointerest}
                                onChange={handleInputChange}
                                checked={formData.nointerest}
                              />
                              <label  id='autorite' for='nointerest' className='field-notes'>Je ne suis pas intéressé</label>      
                            </span>
                          </div>
                       
                          {inputErrors.infosMarket && <div className='redError'>{inputErrors.infosMarket}</div>}
                       </div>
 
                 

                      <div className="dividerForm">
                        <hr/>				
                      </div>
                      
                      <div className='formSection'>
                        <b>Autres informations</b>
                      </div>

                      <div className="form-input col100">
                     
                        <label>
                          Comment avez-vous connu cette page ? </label>
                          <input placeholder='...'
                            type="text"
                            name="knowPage"
                            maxLength="255"
                            onChange={handleInputChange}
                          />
                        

                      </div>

                      <div className='form-input'>
                     
                      {isSubmit !== 3 ? (<ReCAPTCHA sitekey={recaptchaKey} name="handleRecaptchaChange" onChange={handleRecaptchaChange} />):(<></>)}
                      {inputErrors.robot && (<div className='redError'>{inputErrors.robot}</div>)}
                

                      </div>

                   
                                                        
                        <div className='form-input checkboxContainer2'>
                            <div className='field-group'>
                              <span className='field-option'>
                              <input className='field-checkbox'
                            type="checkbox"
                            name="certify"
                            id='certify'
                            value="Je certifie avoir pris connaissance de la Politique de protection des données et l’accepte en tous termes"
                            onChange={handleInputChange}
                            
                          />
                                <label for='certify' className='field-notes' id='autorite' >Je certifie avoir pris connaissance de la <a id='rgpd' target='_blank' href="https://edilians.com/politique-cookies" rel="noopener noreferrer">Politique de protection des données</a> et l’accepte en tous termes</label>      
                              </span>
                            </div>
                            {inputErrors.certify && <div className='redError'>{inputErrors.certify}</div>}

                        </div>



                    <div className='btn-submit'>

                    <button type="submit" disabled={formData.handleRecaptchaChange === '' ? (true):(false) || !isRecaptchaValid}  className={isSubmit !== 0 ? ('isSubmit'):('')}>{isSubmit === 1 ? ('Demande en cours de traitement... ne quittez pas la page'): isSubmit === 2 ?('Echec de la demande, veuillez reessayer'):isSubmit === 3 ?('Demande envoyée !'):("Envoyer")}</button>
                  
                    </div>
                     
                    </form>
                    {showPopup && (
                            <div className="popup">
                            <div className="popup-content">
                              <h3>Votre demande a bien été enregistrée !</h3>
                              <p>Vous allez recevoir prochainement un e-mail de confirmation de prise en charge de votre dossier.</p>
                              

                              <button onClick={() => reloadLocation()}>Fermer</button>
                              <div className='rdv'>
                              <span>rendez-vous sur <a rel="noreferrer noopener" href='https://edilians.com/'>edilians.com</a></span>
                              </div>
                            </div>
                          </div>
                    )}

                  </div>
                  <div className='cgv'>
                  <div className='widgetContainer'>
                    <p>Les données signalées par un * sont obligatoires. Vos données à caractère personnel sont collectées par la société EDILIANS pour son intérêt légitime dans le but de traiter votre demande de réparation de toiture par suite d’une intempérie et vous recontacter. Elles sont destinées aux seules personnes ayant besoin d’en connaître au sein de la Direction marketing, communication et tous services du groupe EDILIANS intéressés par la demande, dans la stricte limite de leurs attributions. Vous disposez des droits suivants : droit d’accès, droit de rectification, droit à l’effacement (« droit à l’oubli »), droit à la limitation et droit d’opposition. Pour exercer vos droits, vous êtes invités à contacter EDILIANS aux coordonnées suivantes : <span className='redEdilians'>donneespersonnelles@edilians.com</span> ou à EDILIANS, Service juridique, 65, chemin du Moulin Carron 69570 DARDILLY. Vous pouvez également adresser une réclamation auprès de la CNIL. Pour plus d’information sur le traitement de vos données à caractère personnel, vous pouvez consulter notre politique en cliquant <span className='redEdilians'><a className='redEdilians' href='https://edilians.com/politique-cookies' target='_blank' rel='noopener noreferrer'>ici</a></span>.</p>

                  </div>

                </div>
                </div>  
              

                  
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Form;
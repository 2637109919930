import './styles/App.scss';

import Form from './Form.js';
import Header from './Header.js';
import Head from './Head.js';
import favicon from './img/favicon_2.ico?v=2';
import favicon2 from './img/favicon.png';


function App() {
  return (



    <>
    <Head/>
     <main>
  
      
      <Header/>
      <Form/>


     </main>
     

    </>
  );
}

export default App;
